import * as React from "react";
import { injectIntl } from "gatsby-plugin-intl";

import { FC } from "react";

type SearchResultItemProps = {
  intl: any;
  title: string;
  subtitle: string;
};
const SearchResultItem = ({ intl }) => {
  return (
    <div className="py-[1.5rem] pl-[1.5rem] mt-[2rem] bg-white flex flex-col w-auto h-auto py-[0.7rem] border border-1 border-[#618EE4] border-opacity-25 justify-center items-start ">
      <h4 className="font-semibold text-[1.25rem] text-[#618EE4] ">
        Клинический анализ крови
      </h4>
      <p className="font-regular text-[0.875rem]">
        Лабораторное исследование <span className="font-semibold">крови</span> ,
        позволяющее оценить физико-химические характеристики...
      </p>
    </div>
  );
};

export default injectIntl(SearchResultItem);
