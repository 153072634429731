import * as React from "react";
import { injectIntl } from "gatsby-plugin-intl";
import { Link } from "gatsby";
import ServicePackageItem from "./ServicePackageItem";
import parse from "html-react-parser";
import ArrowRight from "../../images/arrow_right.svg";

import { servicePackagesObjects } from "../../utils/servicePackagesObjects";
import SearchResultItem from "./SearchResultItem";

// @ts-ignore
const SearchResult = ({ intl }) => {
  return (
    <div className="grid xs:justify-center  sm:pl-0   bg-[#FAFAFA] pb-[10rem]">
      <div className=" flex flex-col sm:grid-rows-2 xs:grid-rows-3 justify-start xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px] sm:mb-[5rem] xs:mb-[2rem] lg:pl-[0rem] ">
        <div className="ml-[0.2rem] mt-[1.5rem] mb-[2.5rem] "></div>
        <h2 className="lg:text-[2rem] sm:text-[1.6rem] xs:text-[1.25rem] font-semibold leading-5">
          {parse(
            intl.formatMessage({
              id: "SearchResult.Title",
            })
          )}
          (5)
        </h2>
        <p className="mt-[2rem] font-regular text-[1.125rem] ">
          {parse(
            intl.formatMessage({
              id: "SearchResult.Title",
            })
          )}
          :<span className="ml-[0.5rem] font-semibold">кровь</span>
        </p>
        {/*Ничего не найдено*/}
        <div className="mt-[2rem] hidden">
          <p className="font-regulat text-[0.875]">
            {parse(
              intl.formatMessage({
                id: "SearchResult.NoResult",
              })
            )}
          </p>
        </div>

        {/*Результаты запроса*/}
        <SearchResultItem />
        <SearchResultItem />
        <SearchResultItem />
      </div>
    </div>
  );
};

export default injectIntl(SearchResult);
